import React, { useState, useEffect, useCallback } from 'react'
import { FormField } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'

export default ({
  formData,
}) => {
  const fetch = useFetch();

  const [isLoading, setIsLoading] = useState(true)
  const [implementations, setImplementations] = useState([])
  const [options, setOptions] = useState([])

  const fetchImplementations = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`accounts/implementations?accountId=${formData.values.accountId}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setImplementations([])
      } else {
        setImplementations(jsonData)
      }
      setIsLoading(false);
      return (jsonData.error ? [] : jsonData)
    } catch (e) {
      console.log(e)
      setImplementations([])
      setIsLoading(false);
    }
    return []
  }

  const refreshOptions = useCallback(() => {
    const newOptions = (!formData.values.accountId ? [{ text: 'Select an account', value: '' }] : 
      implementations.map(a => {
        return {
          text: a.Name,
          value: a.Id,
        }
      })
    )
    setOptions(newOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [implementations])

  useEffect(() => {
    refreshOptions()
  }, [refreshOptions])

  useEffect(() => {
    async function fetchImpl() {
      if (formData.values.accountId) {
        const implementations = await fetchImplementations();
        
        if (implementations.length > 0) {
          // How to select the first one?
        }
      } else {
        setImplementations([])
      }
    }
    fetchImpl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.values.accountId])

  return (
    <FormField
      style={{ flex: 1 }}
      fieldId='implementationId'
      type='search'
      label='Implementation'
      formData={formData}
      options={options}
      isLoading={isLoading}
      maxHeight='200px'
      width='350px'
    />
  );
}