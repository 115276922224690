import { FaShare } from "react-icons/fa";
import ActionButton from "../Shared/ActionButton";

export default ({
  shipment
}) => {
  const {
    id
  } = shipment

  const viewShipment = () => {
    window.open(`https://webstage.shipbob.dev/App/Merchant/#/Orders/${id}/`)
  }

  return (
    <ActionButton onClick={viewShipment}>
      <FaShare style={{ paddingRight: '10px' }} />
      View
    </ActionButton>
  )
}