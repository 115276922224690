import OrderDetails from "./OrderDetails"

export default ({
  orders,
  updateOrderData
}) => {
  return (
    <div>
      {orders.map(i => {
        return <OrderDetails key={i.id} order={i} updateOrderData={updateOrderData} />
      })}
    </div>
  )
}