import React from 'react';
import ShippedItemsBlock from './ShippedItemsBlock/ShippedItemsBlock';
import RecipientDetailsBlock from './RecipientDetailsBlock';
// import { Button } from '@prosolve/common-components-ui'
// import { FaBox } from 'react-icons/fa';
import ShipmentHeader from './ShipmentHeader';

export default ({
  shipment,
  updateShipmentData
}) => {

  const {
    itemImage,
    itemName,
    itemProgramName,
    quantity,
    contactName,
    contactEmail,
    contactPhone,
    address,
    city,
    state,
    zip,
  } = shipment;

  const items = itemName ? [{
    name: itemName,
    image: itemImage,
    programName: itemProgramName,
    quantity
  }] : [];

  const recipientDetails = {
    name: contactName,
    email: contactEmail,
    phone: contactPhone,
    address,
    city,
    state,
    zip,
  }

  return (
    <>
    <div style={{
      border: '1px solid #dedede',
      borderRadius: '5px',
      marginTop: '20px',
    }}>
      <ShipmentHeader shipment={shipment} updateShipmentData={updateShipmentData} />
      <div style={{
        padding: '20px',
        display: 'flex'
      }}>
        <div style={{ flex: 1 }}>
          <ShippedItemsBlock items={items} />
        </div>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
          <RecipientDetailsBlock recipientDetails={recipientDetails} />
        </div>
      </div>
    </div>
    </>
  )
}