import { useState } from "react"
import { Button, Modal } from "@prosolve/common-components-ui"
import useFetch from "../../hooks/useFetch";
import useForm from "../../hooks/useForm";
import ActionButton from "../Shared/ActionButton";
import CreateOrderForm from "./CreateOrderForm";

export default ({
  updateShipmentData,
  /** If the data is not transformed from the shipping web service, noTransform should be set to true */
  noTransform
}) => {
  const [isNewOrderOpen, setIsNewOrderOpen] = useState(false)

  const formSchema = {
    requiredFields: ['accountId', 'quantity', 'contactName', 'contactEmail', 'contactPhone', 'address', 'city', 'state', 'zip', 'requester' ],
    optionalFields: ['implementationId', 'requestedDate', 'earliestArrivalDate', 'desiredArrivalDate', 'shippingInstructions'],
    initData: { 
      accountId: '0015000000uUHFYAA4',
      implementationId: 'a3NJx000003x7gTMAQ',
      productId: 14324038,
      quantity: 1,
      contactName: 'Bill Tester',
      contactEmail: 'bt@prosolve.com',
      contactPhone: '213-733-7890',
      address: '9521 Cobblestone Street',
      city: 'Pacoima',
      state: 'CA',
      zip: '91331',
      requester: 'Paul Dev'
    }
  }

  const formData = useForm(formSchema)

  const [isBusy, setIsBusy] = useState(false);

  const fetch = useFetch();

  const createOrder = async () => {
    setIsBusy(true)
    try {
      const urlEndpoint = 'shipping/order/create'

      const updateData = { ...formData.values };

      const response = await fetch(
        urlEndpoint, 
        { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData)
        }
      )
      const jsonData = await response.json();
      if (jsonData.error) {
        // TODO: Show some kind of error message
        console.log(jsonData.error)
      } else {
        updateShipmentData(!!noTransform ? jsonData.data :  jsonData.data.shipments, true)
        onClose()
      }
      
    } catch (e) {
      console.log(e)
    }
    setIsBusy(false)
  }

  const onClose = () => {
    setIsNewOrderOpen(false)
  }

  const onClickSubmit = () => {
    //console.log('CreateOrder not submitted', formData.values)
    if (formData.isValid()) {
      createOrder()
    }
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true}>
        {'Submit'}
      </Button>
    </div>
  )

  return (
    <>
      {isNewOrderOpen && 
        <Modal title={'Create Order'} onClose={onClose} footer={footer}>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <CreateOrderForm formData={formData} /> 
          </div>
        </Modal>
      }
      <ActionButton onClick={() => setIsNewOrderOpen(true)}>New Order</ActionButton>
    </>
  )
}