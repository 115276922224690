import { useEffect, useState } from "react";
import { FadeInWrapper, Footer, LoadingIcon, Main, MainContent, Section } from "@prosolve/common-components-ui";
import { useGlobalData } from "../../GlobalData";
import useFetch from "../../hooks/useFetch";
import ProductList from "./ProductList";
import AddEditProductButton from "./AddEditProductButton";

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const globalData = useGlobalData();
  const fetch = useFetch()

  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState([])

  const { isMobileView } = globalData;

  const fetchProducts = async () => {
    setIsLoading(true)
    try {
      const result = await fetch('shipping/product')
      const resultJson = await result.json()
      const resultSorted = resultJson.sort((a,b) => b.channel.id - a.channel.id)
      setProducts(resultSorted)
    } finally {
      setIsLoading(false)
    }
  }

  const updateProductData = (data, isCreate) => {
    if (isCreate) {
      const newProducts = [ ...[data], ...products ]
      setProducts(newProducts)
    } else {
      const prodIndex = products.findIndex(p => p.id === data.id)
      const clonedProducts = [ ...products ]
      clonedProducts[prodIndex] = data
      setProducts(clonedProducts)
    }
  }

  useEffect(() => {
    fetchProducts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main $isMobileView={isMobileView} $backgroundColor={'inherit'} style={{ padding: '10px' }}>
      <MainContent $isMobileView={isMobileView}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{
            fontSize: '28px',
            fontWeight: 'bold'
          }}>
            {'Manage Products'}
          </div>
        </div>
        <div>
          <AddEditProductButton updateProductData={updateProductData} />
        </div>
        <div style={{ padding: '0px' }}>
        { 
          isLoading 
          ?
          <Section style={{ background: 'inherit', height: '1200px', alignItems: 'center' }}>
            <LoadingIcon />
          </Section>
          :
          !products?.length ?
          <Section style={{ background: 'inherit', height: '400px' }}>
            Unable to find product data
          </Section>
          :
          <FadeInWrapper>
            <Section style={{ background: 'inherit', padding: '0px' }}>
              <ProductList products={products} updateProductData={updateProductData} />
            </Section>
          </FadeInWrapper>
        }
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}