import ProductDetails from "./ProductDetails"

export default ({
  products,
  updateProductData
}) => {
  return (
    <div>
      {products.map(i => {
        return <ProductDetails key={i.id} product={i} updateProductData={updateProductData} />
      })}
    </div>
  )
}