import ShipmentDetails from "./ShipmentDetails"

export default ({
  shipments,
  updateOrderData
}) => {
  return <div>
    {shipments.map(shipment => 
      <ShipmentDetails key={shipment.id} shipment={shipment} updateOrderData={updateOrderData} />
    )}
  </div>
}