import { useEffect, useState, useCallback } from 'react'
import { FormField } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'

export default ({
  formData,
  selectedVal,
  setSelectedVal,
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [fulfillmentCenters, setFulfillmentCenters] = useState([])
  const [options, setOptions] = useState([])
  const fetch = useFetch()

  const fetchFulfillmentCenter = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`shipping/fulfillmentCenters`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setFulfillmentCenters([])
      } else {
        setFulfillmentCenters(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setFulfillmentCenters([])
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal])

  const refreshOptions = useCallback(() => {
    const newOptions = fulfillmentCenters.map(a => {
      return {
        text: a.name,
        value: a.id,
      }
    })
    newOptions.unshift({
      text: '',
      value: ''
    })
    setOptions(newOptions)
  }, [fulfillmentCenters])
  
  useEffect(() => {
    refreshOptions()
  }, [refreshOptions])
  
  useEffect(() => {
    fetchFulfillmentCenter()
  }, [fetchFulfillmentCenter])

  return (
    <FormField
      style={{ flex: 1 }}
      fieldId='id'
      type='search'
      label='Fulfillment Center'
      formData={formData}
      options={options}
      onChange={(val) => setSelectedVal(val)}
      onSearch={(val) => setSearchVal(val)}
      isLoading={isLoading}
      maxHeight='200px'
      width='350px'
    />
  )
}