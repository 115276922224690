import React from 'react';
import CancelShipmentButton from './CancelShipmentButton';
import ViewShipmentExternalButton from './ViewShipmentExternalButton';
import Products from './Products';

export default ({
  shipment,
  updateOrderData
}) => {
  const {
    id,
    products,
    status
  } = shipment

  const is_active = (status !== 'Cancelled')

  return (<>
    <div style={{
      fontSize: '15px',
      fontWeight: 'bold',
      marginBottom: '20px',
      display: 'flex'
    }}>
      <div style={{ flex: 1 }}>
        {`Shipment #${id}`}
      </div>
      {is_active &&
        <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px', fontSize: '12px' }}>
          <ViewShipmentExternalButton shipment={shipment} />
          <CancelShipmentButton noTransform={true} shipment={shipment} updateShipmentData={updateOrderData} />
        </div>
      }
    </div>
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px'}}>
      <Products products={products} />
    </div>
    </>)
}