import React from 'react';
import moment from 'moment';
import { FaExternalLinkAlt } from 'react-icons/fa';
import CancelShipmentButton from '../Orders/CancelShipmentButton';

export default ({
  shipment,
  updateShipmentData
}) => {
  const {
    trackingNumber,
    shipmentStatus,
    createdDate,
    receivedDate,
    trackingUrl
  } = shipment;

  const formatTitle = () => {
    return shipmentStatus
  }

  const formatDate = () => {
    let displayDate;
    if (shipmentStatus === 'Processing' || shipmentStatus === 'On Hold' || shipmentStatus === 'Exception' || shipmentStatus === 'Cancelled') {
      displayDate = createdDate;
    } else if (shipmentStatus === 'Completed') {
      displayDate = receivedDate;
    }

    return displayDate ? `on ${moment(displayDate).format('MM/DD/YYYY')}` : 'no date found';
  }

  const onOpenTrackingUrl = () => {
    window.open(trackingUrl, '_blank');
  }

  return (
    <div style={{
      background: '#f8f9fc',
      borderBottom: '1px solid #dedede',
      padding: '10px 20px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
      color: '#565656',
      fontWeight: 'bold'
    }}>
      <div style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center'
      }}>
        <div style={{ flex: 1 }}>
          <span style={{ color: '#080808', fontSize: '16px', marginRight: '5px' }}>{formatTitle()}</span>
          <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>{formatDate()}</span>
        </div>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}>
        {
          (shipmentStatus === 'Shipment Sent' || shipmentStatus === 'Shipment Completed') &&
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div>
              {`TRACKING ID: ${trackingNumber || ''}`}
            </div>
            <span onClick={onOpenTrackingUrl} style={{ color: '#0280c6', textDecoration: 'underline', fontWeight: 'normal', cursor: 'pointer', marginLeft: '10px' }}>
              {'Track package'}
            </span>
            <FaExternalLinkAlt onClick={onOpenTrackingUrl} style={{ fontSize: '12px', marginLeft: '5px', color: '#0280c6', cursor: 'pointer' }} />
          </div>
        }
        {
          (shipmentStatus === 'Processing' || shipmentStatus === 'On Hold' || shipmentStatus === 'Exception') &&
          <CancelShipmentButton shipment={shipment} updateShipmentData={updateShipmentData} />
        }
      </div>

    </div>
  )
}