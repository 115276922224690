import { useEffect, useState, useCallback } from 'react'
import { FormField } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'

export default ({
  formData,
  selectedVal,
  setSelectedVal,
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [accounts, setAccounts] = useState([])
  const [options, setOptions] = useState([])
  const fetch = useFetch()

  const fetchAccounts = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`shipments/accounts${searchVal ? `?name=${searchVal}` : ''}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setAccounts([])
      } else {
        setAccounts(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setAccounts([])
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal])

  const refreshOptions = useCallback(() => {
    const newOptions = accounts.map(a => {
      return {
        text: a.Name,
        value: a.Id,
      }
    })
    newOptions.unshift({
      text: '',
      value: ''
    })
    setOptions(newOptions)
  }, [accounts])
  
  useEffect(() => {
    refreshOptions()
  }, [refreshOptions])
  
  useEffect(() => {
    fetchAccounts()
  }, [fetchAccounts])

  // TODO remove when OnChange works
  useEffect(() => {
    setSelectedVal(formData.values.accountId)
  }, [setSelectedVal, formData.values.accountId])

  return (
    <FormField
      style={{ flex: 1 }}
      fieldId='accountId'
      type='search'
      label='Account'
      formData={formData}
      options={options}
      onChange={(val) => { console.log('AccountDropdown onchange') }}
      onSearch={(val) => setSearchVal(val)}
      isLoading={isLoading}
      maxHeight='200px'
      width='350px'
    />
  )
}