import React, { useState, useEffect, useMemo } from 'react';
import { LoadingIcon } from '@prosolve/common-components-ui'
import Chevron from './Chevron';
import MainQuestionAnswerPanel from './MainQuestionAnswerPanel';
import ProgressBar from './ProgressBar';
import { FaTimes } from 'react-icons/fa';
import useFetch from '../../hooks/useFetch';

// import modelData from './testData/StudentStarforcePostProgramSurvey.json';

export default ({
  assessmentName = '',
  classId,
  eventId,
  userId = '',
  teamColor = '',
  hasCloseButton,
  isPreview,
  previewData = {},
  onClose = () => {},
  onSuccess = () => {}
}) => {
  const fetch = useFetch();

  const initAssessmentData = isPreview ? previewData : {};

  const initShowIntroPage = isPreview ? Boolean(previewData.intro) : undefined;

  const [assessmentData, setAssessmentData] = useState(initAssessmentData)
  const [showIntroPage, setShowIntroPage] = useState(initShowIntroPage);
  const [loading, setLoading] = useState(isPreview ? false : true)

  const {
    _id: assessmentId,
    backgroundImage,
    intro,
    questions = []
  } = assessmentData;

  const getAssessmentData = async () => {
    try {
      setLoading(true)

      const response = await fetch(`assessment?name=${assessmentName}`, { method: 'GET' })
      const jsonData = await response.json();

      const data = jsonData || {};

      setAssessmentData(data)

      setShowIntroPage(Boolean(data.intro))

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      onClose()
    }
  }

  useEffect(() => {
    if (!isPreview) {
      getAssessmentData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview])

  const categoryInfoMap = useMemo(() => {
    const catInfoMap = {};
    questions.forEach(({id, category}) => {
      if (category) {
        if (!catInfoMap[category]) {
          catInfoMap[category] = [];
        }
        catInfoMap[category].push(id)
      }
    });
    return catInfoMap;
  }, [questions]);

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0)

  const currentQuestion = questions[currentQuestionNumber] || {}

  const { 
    id: currentQuestionId, 
    type: currentQuestionType,
    // answerOptions: currentQuestionAnswerOptions = []
  } = currentQuestion;

  const [answers, setAnswers] = useState({})

  const hasIntro = Boolean(intro);

  const isPrevDisabled = hasIntro ? false : currentQuestionNumber === 0;

  const currentAnswerObj = answers[currentQuestionId] || {};
  const {
    answerText = '',
    answerArray = []
  } = currentAnswerObj;

  let currentQuestionHasBeenAnswered;
  if (currentQuestionType === 'multitext') {
    let answerCount = 0;
    answerArray.forEach(({ answerText }) => {
      if (answerText) {
        answerCount++;
      }
    });
    currentQuestionHasBeenAnswered = answerCount > 0; // (answerCount === currentQuestionAnswerOptions.length);
  } else {
    currentQuestionHasBeenAnswered = answerText || (answerArray && answerArray.length > 0);
  }

  const isNextDisabled = !currentQuestionHasBeenAnswered;

  const isLastQuestion = currentQuestionNumber === (questions.length - 1);

  const onPrevQuestion = () => {
    if (!isPrevDisabled) {
      if (hasIntro && currentQuestionNumber === 0) {
        setShowIntroPage(true)
      } else {
        setCurrentQuestionNumber(currentQuestionNumber - 1);
      }
    }
  }

  const onNextQuestion = (event, forceNext) => {
    if ((forceNext && !isLastQuestion) || (!isNextDisabled && !isLastQuestion)) {
      setCurrentQuestionNumber(currentQuestionNumber + 1);
    }
  }

  const peformAutoNextAction = () => {
    setTimeout(() => {
      onNextQuestion(undefined, true);
    }, 300)
  }

  const onSubmitAnswers = async () => {
    if (!isPreview) {
      try {
        setLoading(true)

        const answerSubmissionObj = {
          assessmentId,
          assessmentName,
          answers
        }

        if (userId) {
          answerSubmissionObj.userId = userId;
        }

        if (classId) {
          answerSubmissionObj.classId = classId;
        }

        if (eventId) {
          answerSubmissionObj.eventId = eventId;
        }

        if (teamColor) {
          answerSubmissionObj.teamColor = teamColor;
        }


        const response = await fetch(`assessment/submit`, 
        { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: answerSubmissionObj })
        })

        const jsonData = await response.json();

        if (jsonData.success) {
          onSuccess();
        }

        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    } else {
      onClose();
    }
  }

  const completedPercentage = (currentQuestionNumber / questions.length) * 100;
  const progressPercentage = isLastQuestion && currentQuestionHasBeenAnswered ? 100 : completedPercentage;

  const introObj = intro || {};

  const {
    title = '',
    subTitle = '',
    buttonText = 'Get Started',
  } = introObj;

  const wrapperStyle = {
    position: 'relative', 
    height: 'calc(100vh)', 
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  }

  if (backgroundImage) {
    wrapperStyle.backgroundImage = `url(${backgroundImage})`;
    wrapperStyle.backgroundSize = 'cover';
    wrapperStyle.backgroundPosition = 'center center';
  } else {
    wrapperStyle.background = '#1282B2';
  }

  return (
    <div style={wrapperStyle}>
      {
        hasCloseButton &&
        <div 
          style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '12px',
            right: '5px',
            background: '#7E7E7E',
            padding: '8px',
            borderRadius: '50%'
          }}
          onClick={onClose}
        >
          <div style={{ 
            color: '#FFFFFF',
            fontSize: '22px',
            width: '22px',
            height: '22px',
          }}>
            <FaTimes />
          </div>
        </div>
      }
      {
        loading ?
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          background: backgroundImage ? '#000000c2' : 'inherit',
          fontSize: '16px',
          color: '#d2275a',
          overflow: 'auto',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <LoadingIcon />
        </div>
        :
        showIntroPage ?
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          background: backgroundImage ? '#000000c2' : 'inherit',
          fontSize: '16px',
          color: '#ffffff',
          overflow: 'auto',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <div style={{ fontSize: '48px' }}>
            {title}
          </div>
          <div style={{ margin: '30px 0px', fontSize: '22px', whiteSpace: 'pre-wrap', width: '80%', textAlign: 'center' }}>
            {subTitle}
          </div>
          <div 
            style={{
              padding: '15px 20px',
              background: '#d2275a',
              border: '1px solid 7E7E7E',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
            onClick={() => setShowIntroPage(false)}
          >
            {buttonText}
          </div>
        </div>
        :
      <>
      <ProgressBar percentage={progressPercentage} />
      <Chevron direction='left' onClick={onPrevQuestion} isDisabled={isPrevDisabled} />
      {
        isLastQuestion && currentQuestionHasBeenAnswered
        ?
        <div 
          style={{
            position: 'absolute',
            marginTop: '10px',
            top: '50%',
            right: '15px',
            padding: '15px 25px',
            borderRadius: '15px',
            background: '#d2275a',
            color: '#ffffff',
            cursor: 'pointer',
            fontSize: '22px'
          }}
          onClick={onSubmitAnswers}
        >
          {'Submit'}
        </div>
        :
        <Chevron direction='right' onClick={onNextQuestion} isDisabled={isNextDisabled || isLastQuestion} />
      }
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        background: backgroundImage ? '#000000c2' : 'inherit',
        fontSize: '16px',
        color: '#ffffff',
        overflow: 'auto'
      }}>
        <MainQuestionAnswerPanel
          key={currentQuestion.id}
          questionObj={currentQuestion}
          categoryInfoMap={categoryInfoMap}
          answers={answers}
          setAnswers={setAnswers}
          peformAutoNextAction={peformAutoNextAction}
        />
      </div>
      </>
      }
    </div>
  )
}