import { useState } from "react"
import { Button, FormField, Modal, } from "@prosolve/common-components-ui"
import ActionButton from '../Shared/ActionButton'
import useFetch from "../../hooks/useFetch";
import useForm from "../../hooks/useForm";

// If product is present, the form will edit instead.
export default ({
  product,
  updateProductData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isCreate = !product?.id

  const randomId = (new Date()).getTime().toString().slice(-5) + '-' + Math.floor(Math.random() * 1000)
  const formSchema = {
    requiredFields: ['name', 'reference_id'],
    optionalFields: ['sku', 'barcode', 'gtin', 'upc', 'unit_price'],
    initData: (product ?? {
      reference_id: `TST-R0-${randomId}`,
      sku: `TST-S0-${randomId}`,
      name: `Test Product ${randomId}`,
      barcode: '',
      gtin: '',
      upc: '',
      unit_price: ''
    })
  }

  const formData = useForm(formSchema)

  const [isBusy, setIsBusy] = useState(false);

  const fetch = useFetch();

  const createEditProduct = async () => {
    setIsBusy(true)
    try {

      const urlEndpoint = isCreate ? 'shipping/product/create' : 'shipping/product/edit'
      const updateData = { ...formData.values, id: product?.id };
      const response = await fetch(urlEndpoint, { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData)
        }
      )

      const jsonData = await response.json();
      if (jsonData.error) {
        // TODO: Show some kind of error message
        console.log(jsonData.error)
      } else {
        updateProductData(jsonData, isCreate)
        onClose()
      }
      
    } catch (e) {
      console.log(e)
    }
    setIsBusy(false)
  }

  const onClose = () => {
    setIsModalOpen(false)
  }

  const onClickSubmit = () => {
    //console.log(formData.values)
    if (formData.isValid()) {
      createEditProduct()
    }
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true}>
        {'Submit'}
      </Button>
    </div>
  )

  return (
    <>
      {isModalOpen && 
        <Modal title={'Create Order'} onClose={onClose} footer={footer}>
          <div style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '15px'
            }}>
              <div style={{
                display: 'flex',
                columnGap: '15px'
              }}>
                <FormField
                  style={{ flex: 1 }}
                  fieldId='name'
                  type='text'
                  label='Product Name'
                  formData={formData}
                />
              </div>
              <div style={{
                display: 'flex',
                columnGap: '15px'
              }}>
                <FormField
                  style={{ flex: 1 }}
                  fieldId='reference_id'
                  type='text'
                  label='Reference ID (SKU, but permanent)'
                  formData={formData}
                  disabled={!isCreate}
                />
                <FormField
                  style={{ flex: 1 }}
                  fieldId='sku'
                  type='text'
                  label='SKU'
                  formData={formData}
                />
              </div>
              <div style={{
                display: 'flex',
                columnGap: '15px'
              }}>
                <FormField
                  style={{ flex: 1 }}
                  fieldId='barcode'
                  type='text'
                  label='Barcode'
                  formData={formData}
                />
              </div>
              <div style={{
                display: 'flex',
                columnGap: '15px'
              }}>
                <FormField
                  style={{ flex: 1 }}
                  fieldId='gtin'
                  type='text'
                  label='GTIN'
                  formData={formData}
                />
              </div>
              <div style={{
                display: 'flex',
                columnGap: '15px'
              }}>
                <FormField
                  style={{ flex: 1 }}
                  fieldId='upc'
                  type='text'
                  label='UPC'
                  formData={formData}
                />
              </div>
              <div style={{
                display: 'flex',
                columnGap: '15px'
              }}>
                <FormField
                  style={{ flex: 1 }}
                  fieldId='unit_price'
                  type='text'
                  label='Unit Price'
                  formData={formData}
                />
              </div>
            </div>
          </div>
        </Modal>
      }
      <ActionButton onClick={() => setIsModalOpen(true)}>
        {isCreate ? 'New Product' : 'Edit Product'}
      </ActionButton>
    </>
  )
}