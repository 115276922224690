import { FaShare } from "react-icons/fa";
import ActionButton from "../Shared/ActionButton";

export default ({
  product
}) => {
  const {
    fulfillable_inventory_items
  } = product

  const {
    id: fid
  } = fulfillable_inventory_items?.[0]

  const viewProduct = () => {
    // https://webstage.shipbob.dev/app/merchant/#/product-catalog
    window.open(`https://webstage.shipbob.dev/App/Merchant/#/Products/${fid}`)
  }

  return (
    <ActionButton onClick={viewProduct}>
      <FaShare style={{ paddingRight: '10px' }} />
      View
    </ActionButton>
  )
}