import { useEffect, useRef } from "react"
import { FormField } from "@prosolve/common-components-ui"
import useForm from "../../hooks/useForm";
import ProductDropdown from "../Shared/ProductDropdown"
import FulfillmentCenterDropdown from "../Shared/FulfillmentCenterDropdown";

export default ({
  initialWRO,
  onChange,
  sendValidator
}) => {
  if (!!initialWRO) {
    console.log('CreateWROForm.wroInitial', initialWRO)
  }

  const tomorrow = (new Date())
  tomorrow.setDate(tomorrow.getDate() + 1)

  const formSchemaOrder = {
    requiredFields: ['fulfillment_center', 'package_type', 'box_packaging_type'],
    optionalFields: ['boxes', 'inventory_quantities'],
    initData: (initialWRO ?? {
      fulfillment_center: {
        id: 14
      }, // id 41 (Hillsboro)
      package_type: 'Package',
      box_packaging_type: 'EverythingInOneBox',
      boxes: [
        {
          tracking_number: 'AASDLFKJ124234LSKDJF',
          box_items: [{
            quantity: 1,
            inventory_id: 7922210
          }]
        },
        {
          tracking_number: 'AASDLFKJ124234LSKDJF222',
          box_items: [{
            quantity: 2,
            inventory_id: 7922210
          }]
        }
      ],
      expected_arrival_date: tomorrow
    })
  }

  const formDataOrder = useForm(formSchemaOrder)
  const formDatas = useRef([formDataOrder])

  const orderData = useRef({ ...(formDataOrder.values) })

  useEffect(() => {
    sendValidator(validate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validate = () => {
    //console.log('onClickSubmit.formDataOrder', formDataOrder)
    formDataOrder.setValues(orderData.current)
    return formDatas.current.reduce((acc, formData) => acc && formData.isValid(), true)
  }

  const onFulfillmentCenterChange = (fulfillmentCenter) => {
    orderData.current.fulfillment_center = fulfillmentCenter
    onChange(orderData.current)
  }

  const FulfillmentCenter = ({ fulfillmentCenter, onChange }) => {
    const formSchema = {
      requiredFields: [],
      optionalFields: ['id'],
      initData: fulfillmentCenter
    }
    const formData = useForm(formSchema)

    useEffect(() => {
      onChange(formData.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.values.id])

    return <FulfillmentCenterDropdown formData={formData} />
  }

  const onFormBoxChange = (box, key) => {
    const clone = { ...formDataOrder.values }
    clone.boxes[key] = box
    //formDataOrder.setValues(clone)
    orderData.current = clone
    onChange(orderData.current)
  }

  const FormBox = ({ box, boxKey, onChange }) => {
    //console.log('box', box)
    const formSchema = {
      requiredFields: ['tracking_number', 'box_items'],
      optionalFields: [],
      initData: box
    }
    const formData = useForm(formSchema)
    console.log('formDatas.length, TODO fix this', formDatas.current.length)
    formDatas.current.push(formData)

    useEffect(() => {
      onChange(formData.values, boxKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.values])

    const onBoxItemChange = (box_item, i) => {
      const clone = { ...formData.values }
      clone.box_items[i] = box_item
      formData.setValues(clone)
    }

    return (
      <div style={{ paddingBottom: '20px' }}>
        <div style={{ paddingBottom: '10px' }}>
          <FormField
            style={{ flex: 1 }}
            fieldId='tracking_number'
            type='text'
            label='Tracking Number'
            formData={formData}
          />
        </div>

        {box.box_items.map((box_item, i) =>
          <FormBoxItem key={i} box_item={box_item} onChange={(changed_box_item) => onBoxItemChange(changed_box_item, i)} />
        )}
      </div>
    )
  }

  const FormBoxItem = ({ box_item, onChange }) => {
    //console.log('box_item', box_item)
    const formSchema = {
      requiredFields: ['inventory_id', 'quantity'],
      optionalFields: [],
      initData: box_item
    }
    const formData = useForm(formSchema)

    const formSchemaProduct = {
      requiredFields: ['productId'],
      optionalFields: [],
      initData: ''
    }
    const formDataProduct = useForm(formSchemaProduct)

    useEffect(() => {
      onChange(formData.values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.values])

    useEffect(() => {
      formData.setValues({ ...formData.values, ...{ product_id: formDataProduct.values.productId } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDataProduct.values])

    return (
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <ProductDropdown formData={formDataProduct} />
        <FormField
          style={{ flex: 1 }}
          fieldId='quantity'
          type='text'
          label='Quantity'
          formData={formData}
        />
      </div>
    )
  }

  const FormInventoryQuantities = ({ inventory_quantities }) => {
    return (
      <div style={{ paddingTop: '20px' }}>
        {inventory_quantities?.map((inventory_quantity, index) => 
          <FormInventoryQuantity key={index} inventory_quantity={inventory_quantity} />
        )}
      </div>
    )
  }

  const FormInventoryQuantity = ({ inventory_quantity }) => {
    const formData = useForm({ requiredFields: [], optionalFields: ['sku', 'expected_quantity'], initData: inventory_quantity})
    return (
      <div style={{
        paddingBottom: '20px',
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField style={{ flex: 1}} type='text' label='Product SKU' fieldId='sku' formData={formData} disabled={true} />
        <FormField style={{ flex: 1}} type='text' label='Quantity' fieldId='expected_quantity' formData={formData} disabled={true} />
      </div>
    )
  }

  return (
    <>
      <div style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '15px'
        }}>
          <div style={{
            display: 'flex',
            columnGap: '15px'
          }}>
            <FulfillmentCenter fulfillmentCenter={formDataOrder.values.fulfillment_center} onChange={onFulfillmentCenterChange} />
            <FormField
              style={{ flex: 1 }}
              fieldId='expected_arrival_date'
              type='date'
              label='Expected Arrival Date'
              formData={formDataOrder}
            />
          </div>
          <div style={{
            display: 'flex',
            columnGap: '15px'
          }}>
            <FormField
              style={{ flex: 1 }}
              fieldId='package_type'
              type='text'
              label='Package Type'
              formData={formDataOrder}
            />
            <FormField
              style={{ flex: 1 }}
              fieldId='box_packaging_type'
              type='text'
              label='Box Packaging Type'
              formData={formDataOrder}
            />
          </div>

          {formDataOrder.values.boxes &&
            <div style={{ paddingTop: '20px' }}>
              {formDataOrder.values.boxes.map((box, index) => 
                <FormBox key={index} boxKey={index} box={box} onChange={onFormBoxChange} />
              )}
            </div>
          }

          {initialWRO?.inventory_quantities &&
            <div style={{ paddingTop: '20px' }}>
              <FormInventoryQuantities inventory_quantities={initialWRO?.inventory_quantities} />
            </div>
          }

        </div>
      </div>
    </>
  )
}