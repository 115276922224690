import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalData } from '../GlobalData';
import { Main, MainContent, Section, Footer, FadeInWrapper, Button } from '@prosolve/common-components-ui'
import ProsolveLogoSlim from '../images/prosolve-logo-slim.png';
import AccountSelector from './AccountSelector';

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const globalData = useGlobalData();
  const navigate = useNavigate();

  const { isMobileView } = globalData;

  return (
    <Main $isMobileView={isMobileView} 
    $backgroundColor={'#ffffff'}
    >
      <MainContent $isMobileView={isMobileView}>
        <div style={{ padding: '0px' }}>
          <FadeInWrapper>
            <Section style={{ padding: '0px' }}>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <img src={ProsolveLogoSlim} style={{ height: '55px', marginLeft: '-10px', marginRight: '10px' }} alt="prosolveLogo"/>
                <div style={{
                  fontSize: '28px',
                  fontWeight: 'bold'
                }}>
                  {'Shipment Tracker'}
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <div>
                  <div style={{   fontSize: '15px', color: '#5a5a5a'}}>
                    {'Inventory / Product Management'}
                  </div>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Button 
                    style={{ display: 'inline-flex', width: '300px' }} 
                    onClick={() => navigate('/shipments/products')} 
                    $colored={true}>
                    {'Manage Products'}
                  </Button>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Button 
                    style={{ display: 'inline-flex', width: '300px' }} 
                    onClick={() => navigate('/shipments/inventory')} 
                    $colored={true}>
                    {'View Inventory'}
                  </Button>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Button 
                    style={{ display: 'inline-flex', width: '300px' }} 
                    onClick={() => navigate('/shipments/WRO')} 
                    $colored={true}>
                    {'Warehouse Receiving Orders'}
                  </Button>
                </div>
                <div style={{ marginTop: '20px' }}>
                  <Button 
                    style={{ display: 'inline-flex', width: '300px' }} 
                    onClick={() => navigate('/shipments/orders')} 
                    $colored={true}>
                    {'Manage Orders'}
                  </Button>
                </div>
              </div>
              <div style={{ margin: '30px', borderBottom: 'solid 1px silver' }}></div>
              <div>
                <AccountSelector />
              </div>
            </Section>
          </FadeInWrapper>
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}
