import { useState } from "react"
import { FormField } from "@prosolve/common-components-ui"
import AccountDropdown from "../Shared/AccountDropdown"
import ImplementationSelector from "../Shared/ImplementationSelector"
import ProductDropdown from "../Shared/ProductDropdown"

export default({
  formData
}) => {
  const [selectedAccount, setSelectedAccount] = useState('')

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      rowGap: '15px'
    }}>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <AccountDropdown formData={formData} selectedVal={selectedAccount} setSelectedVal={setSelectedAccount} />
        <ImplementationSelector formData={formData} />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <ProductDropdown formData={formData} isUseSku={true} />
        <FormField
          style={{ flex: 1 }}
          fieldId='quantity'
          type='text'
          label='Quantity'
          formData={formData}
        />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='contactName'
          type='text'
          label='Contact Name'
          formData={formData}
        />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='contactEmail'
          type='text'
          label='Contact Email'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='contactPhone'
          type='text'
          label='Contact Phone'
          formData={formData}
        />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='address'
          type='text'
          label='Shipping Address'
          formData={formData}
        />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='city'
          type='text'
          label='City'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='state'
          type='text'
          label='State'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='zip'
          type='text'
          label='Zip'
          formData={formData}
        />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='desiredArrivalDate'
          type='date'
          label='Desired Arrival Date'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='earliestArrivalDate'
          type='date'
          label='Earliest Arrival Date'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='shippingInstructions'
          type='text'
          label='Special Shipping Instructions'
          formData={formData}
        />
      </div>
      <div style={{
        display: 'flex',
        columnGap: '15px'
      }}>
        <FormField
          style={{ flex: 1 }}
          fieldId='requester'
          type='text'
          label='Requester'
          formData={formData}
        />
        <FormField
          style={{ flex: 1 }}
          fieldId='requestedDate'
          type='date'
          label='Date Requested'
          formData={formData}
        />
      </div>
    </div>
  )

}