import { useEffect, useState, useCallback } from 'react'
import { FormField } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch'

export default ({
  formData,
  selectedVal,
  setSelectedVal,
  isUseSku
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [options, setOptions] = useState([])
  const fetch = useFetch()

  const fetchProducts = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`shipping/product`)
      const jsonData = await response.json();
      if (jsonData.error) {
        setProducts([])
      } else {
        setProducts(jsonData)
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e)
      setProducts([])
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal])

  const refreshOptions = useCallback(() => {
    const newOptions = products.map(a => {
      return {
        text: a.name,
        value: (!isUseSku ? a.id : a.reference_id),
      }
    })
    //console.log('products', products, 'newOptions', newOptions)
    setOptions(newOptions)
  }, [products, isUseSku])
  
  useEffect(() => {
    refreshOptions()
  }, [refreshOptions])
  
  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  return (
    <FormField
      style={{ flex: 1 }}
      fieldId={!isUseSku ? 'productId' : 'productSku'}
      type='search'
      label='Product'
      formData={formData}
      options={options}
      onChange={(val) => setSelectedVal(val)}
      onSearch={(val) => setSearchVal(val)}
      isLoading={isLoading}
      maxHeight='200px'
      width='350px'
    />
  )
}