import styled from "styled-components"
import ViewInventoryItemExternalButton from "./ViewInventoryItemExternalButton"

const InventoryTable = styled.table`
  & {
    width: 100%
  }

  * th {
    text-align: left
  }
`

export default ({
  inventoryItem
}) => {
  const {
    is_active,
    total_awaiting_quantity,
    total_backordered_quantity,
    total_committed_quantity,
    total_exception_quantity,
    total_fulfillable_quantity,
    total_internal_transfer_quantity,
    total_onhand_quantity,
    total_sellable_quantity,
  } = inventoryItem

  return (
    
    <div style={{
      background: 'white',
      border: '1px solid #dedede',
      borderRadius: '5px',
      marginTop: '20px',
      opacity: (!!is_active ? 1 : 0.5)
    }}>
      <div style={{
        background: '#f8f9fc',
        borderBottom: '1px solid #dedede',
        padding: '10px 20px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#080808'
      }}>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center'
        }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '16px', marginRight: '5px' }}>{inventoryItem.name}</span>
            {!is_active && <span>(retired)</span>}
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <ViewInventoryItemExternalButton inventoryItem={inventoryItem} />
          </div>
        </div>
      </div>
      
      <div style={{
        padding: '20px',
        display: 'flex'
      }}>
        <div style={{ flex: 1 }}>
          <InventoryTable>
            <thead>
              <tr>
                <th>Sellable</th>
                <th>On&nbsp;Hand</th>
                <th>Committed</th>
                <th>Fulfilled</th>
                <th>Exception</th>
                <th>Backordered</th>
                <th>Incoming</th>
                <th>Transfer</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{total_sellable_quantity}</td>
                <td>{total_onhand_quantity}</td>
                <td>{total_committed_quantity}</td>
                <td>{total_fulfillable_quantity}</td>
                <td>{total_exception_quantity}</td>
                <td>{total_backordered_quantity}</td>
                <td>{total_awaiting_quantity}</td>
                <td>{total_internal_transfer_quantity}</td>
              </tr>
            </tbody>
          </InventoryTable>
        </div>
      </div>
    </div>
  )
}