import { useState } from "react"
import { Button, Modal } from "@prosolve/common-components-ui"
import useFetch from "../../hooks/useFetch";
import ActionButton from "../Shared/ActionButton";

// If product is present, the form will edit instead.
export default ({
  wro,
  updateWROData
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [isBusy, setIsBusy] = useState(false);

  const fetch = useFetch();

  const cancelWRO = async () => {
    setIsBusy(true)
    try {

      const urlEndpoint = `shipping/wro/cancel`
      const data = {
        id: wro.id
      }
      const response = await fetch(urlEndpoint, { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        }
      )

      const jsonData = await response.json();
      if (jsonData.error) {
        // TODO: Show some kind of error message
        console.log(jsonData.error)
      } else {
        updateWROData(wro, false)
        onClose()
      }
      
    } catch (e) {
      console.log(e)
    }
    setIsBusy(false)
  }

  const onClose = () => {
    setIsModalOpen(false)
  }

  const onClickSubmit = () => {
    //console.log(formData.values)
    cancelWRO()
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true}>
        {'Submit'}
      </Button>
    </div>
  )

  return (
    <>
      {isModalOpen && 
        <Modal title={'Create Order'} onClose={onClose} footer={footer}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            fontSize: '14px',
            fontWeight: 'normal'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '15px'
            }}>
              Are you sure you want to cancel WRO: #{wro?.id}
            </div>
          </div>
        </Modal>
      }
      <ActionButton onClick={() => setIsModalOpen(true)}>
        Cancel WRO
      </ActionButton>
    </>
  )
}