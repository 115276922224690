import WRODetails from "./WRODetails"

export default ({
  wros,
  updateWROData
}) => {
  return (
    <div>
      {wros.map(wro => {
        return <WRODetails key={wro.id} wro={wro} updateWROData={updateWROData} />
      })}
    </div>
  )
}