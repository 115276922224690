import moment from "moment"
import Shipments from "./Shipments"
import RecipientDetails from "./RecipientDetails"

export default ({
  order,
  updateOrderData
}) => {
  const {
    id,
    project,
    status,
    created_date,
    recipient,
    shipments
  } = order

  const normalStatusMap = {
    'ImportReview': 'Import Review',
    'Processing': 'Processing',
    'PartiallyFulfilled': 'Partially Fulfilled',
    'Fulfilled': 'Fulfilled'
  }

  const is_active = (status !== 'Cancelled')

  const formatDate = () => {
    const displayDate = created_date
    return displayDate ? `on ${moment(displayDate).format('MM/DD/YYYY')}` : 'no date found'
  }

  return (
    
    <div style={{
      background: 'white',
      border: '1px solid #dedede',
      borderRadius: '5px',
      marginTop: '20px',
      color: (!!is_active ? '#080808' : '#bfbfbf')
    }}>
      <div style={{
        background: '#f8f9fc',
        borderBottom: '1px solid #dedede',
        padding: '10px 20px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        fontWeight: 'bold'
      }}>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center'
        }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '16px', marginRight: '5px' }}>{project ? project : 'No Account'} - Order #{id} - {status}</span>
            <span style={{ fontStyle: 'italic', fontWeight: 'normal', color: (!!is_active ? '#565656' : 'inherit') }}>{formatDate()}</span>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            {/* Cancel Order Button */}
          </div>
        </div>
      </div>
      <div>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center'
        }}>
          {Object.entries(normalStatusMap).map(([key, val], i, arr) => 
            <div key={key} style={{ 
              flex: 1, 
              textAlign: 'center',
              background: (status === key && !!is_active ? '#f8f9fc' : 'inherit'), 
              color: (!!is_active ? '#565656' : 'inherit'),
              fontWeight: (status === key ? 'bold' : 'normal'),
              borderRight: (i === arr.length - 1 ? '' : 'solid 1px #dedede'),
              borderBottom: 'solid 1px #dedede',
              padding: '2px 0'
            }}>
              <span>{val}</span>
            </div>
          )}
        </div>
      </div>
      
      <div style={{
        padding: '20px',
        display: 'flex'
      }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', fontSize: '14px' }}>
          <RecipientDetails recipient={recipient} />
        </div>
        <div style={{ flex: 1 }}>
          <Shipments shipments={shipments} updateOrderData={updateOrderData} />
        </div>
      </div>
    </div>
  )
}