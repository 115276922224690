import { useEffect, useState } from "react";
import { FadeInWrapper, Footer, LoadingIcon, Main, MainContent, Section } from "@prosolve/common-components-ui";
import { useGlobalData } from "../../GlobalData";
import useFetch from "../../hooks/useFetch";
import InventoryList from "./InventoryList";

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const globalData = useGlobalData();
  const fetch = useFetch()

  const [isLoading, setIsLoading] = useState(true)
  const [inventory, setInventory] = useState([])

  const { isMobileView } = globalData;

  const fetchInventory = async () => {
    setIsLoading(true)
    try {
      const result = await fetch('shipping/inventory')
      const data = await result.json()
      console.log('data', data)
      const dataSorted = data.sort((a,b) => (a.is_active ? -1 : 1))
      setInventory(dataSorted)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchInventory()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main $isMobileView={isMobileView} $backgroundColor={'inherit'} style={{ padding: '10px' }}>
      <MainContent $isMobileView={isMobileView}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <div style={{
            fontSize: '28px',
            fontWeight: 'bold'
          }}>
            {'Inventory'}
          </div>
        </div>
        <div style={{ padding: '0px' }}>
        { 
          isLoading 
          ?
          <Section style={{ background: 'inherit', height: '1200px', alignItems: 'center' }}>
            <LoadingIcon />
          </Section>
          :
          !inventory?.length ?
          <Section style={{ background: 'inherit', height: '400px' }}>
            Unable to find inventory data
          </Section>
          :
          <FadeInWrapper>
            <Section style={{ background: 'inherit', padding: '0px' }}>
              <InventoryList inventory={inventory} />
            </Section>
          </FadeInWrapper>
        }
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}