import React from 'react';
import ImagePlaceholder from '../../images/image-placeholder.jpg'

export default ({
  product
}) => {
  const {
    name,
    inventory_items,
    image,
  } = product;

  const quantity = inventory_items.reduce((total, val) => total + val.quantity, 0)

  const imageSrc = image || ImagePlaceholder;

  return (
    <div style={{
      display: 'flex',
      color: '#565656',
      fontSize: '14px'
    }}>
      <img src={imageSrc} style={{ height: '70px', marginRight: '10px' }} alt=""/>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '7px',
        marginTop: '2px',
        flex: 1
      }}>
        <div style={{ color: '#080808', fontSize: '16px'}}>{name}</div>
        <div>{`Quantity: ${quantity || ''}`}</div>
        {/* Missing item report button */}
      </div>
    </div>
  )
}