import React, { useState } from "react";
import { Modal, Button, FormField } from '@prosolve/common-components-ui'
import useFetch from '../../hooks/useFetch';
import useForm from '../../hooks/useForm';
import ActionButton from "../Shared/ActionButton";

export default ({
  shipment,
  updateShipmentData,
  /** If the data is not transformed from the shipping web service, noTransform should be set to true */
  noTransform
}) => {
  const {
    id,
  } = shipment;

  const [isShowModal, setIsShowModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const fetch = useFetch();

  const formSchema = {
    //requiredFields: ['contactName', 'contactEmail', 'contactPhone', 'address', 'city', 'state', 'zip', 'requestedDate', 'requester', 'desiredArrivalDate' ],
    optionalFields: ['quantity', 'earliestArrivalDate', 'shippingInstructions'],
    initData: shipment
  }

  const formData = useForm(formSchema)

  const cancelShipment = async () => {
    setIsBusy(true)
    try {

      const urlEndpoint = 'shipping/shipment/cancel'
      const updateData = { 
        ...formData.values, 
        id,
        ...{ shipmentStatus: 'Cancelled' }
      }

      const response = await fetch(
        urlEndpoint, 
        { 
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData)
        }
      )

      const jsonData = await response.json();
      if (jsonData.error) {
        // TODO: Show some kind of error message
        console.log(jsonData.error)
      } else {
        updateShipmentData(!!noTransform ? jsonData : updateData)
        onClose()
      }
      
    } catch (e) {
      console.log(e)
      // setNoData(true)
      // setShipmentData(undefined)
    }
    setIsBusy(false)
  }

  const onClose = () => {
    setIsShowModal(false)
  }

  const onClickSubmit = () => {
    if (formData.isValid()) {
      cancelShipment()
    }
  }

  const footer = (
    <div style={{ 
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      columnGap: '10px',
      fontSize: '16px',
      fontWeight: 'normal',
      padding: '10px'
    }}>
      {
        Boolean(isBusy) &&
        <div>{'Updating...'}</div>
      }
      <Button onClick={onClose} style={{ disabled: isBusy }}>
        {'Cancel'}
      </Button>
      <Button onClick={onClickSubmit} $colored={true} style={{ disabled: isBusy }}>
        {'Submit'}
      </Button>
    </div>
  )

  return (<>
    { !isShowModal ? <></> : 
      <Modal title='Cancel Shipment' onClose={onClose} footer={footer}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '16px',
          fontWeight: 'normal'
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '15px'
          }}>
            <FormField
              style={{ flex: 1, display: 'none' }}
              fieldId='id'
              type='text'
              label='Units Delivered'
              formData={formData}
            />
            {`Are you sure you want to cancel shipment #${shipment.id}?`}
          </div>
        </div>
      </Modal>
    }

    <ActionButton onClick={() => setIsShowModal(true)}>
      Cancel&nbsp;Shipment
    </ActionButton>
  </>)
}