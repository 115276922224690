import React, { useState, useEffect } from 'react';
import { useGlobalData } from '../GlobalData';
import { Main, MainContent, Section, Footer, LoadingIcon, FadeInWrapper } from '@prosolve/common-components-ui'
import useFetch from '../hooks/useFetch';
import ShipmentList from './ShipmentList';
import ProsolveLogoSlim from '../images/prosolve-logo-slim.png';
import { useNavigate } from "react-router-dom";

const SectionDivider = () => <div style={{ height: '10px' }}>&nbsp;</div>

export default () => {
  const urlParams = new URLSearchParams(window.location.search);
  const accountId = urlParams.get('id');

  const fetch = useFetch();

  const navigate = useNavigate();

  const globalData = useGlobalData();
  const { isMobileView } = globalData;

  const [shipmentData, setShipmentData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [noData, setNoData] = useState(false)
  const [forceTabCountRefresh, setForceTabCountRefresh] = useState(false)

  const fetchShipmentData = async () => {
    setIsLoading(true)
    try {
      const response = await fetch(`shipping/order?id=${accountId || ''}`, { method: 'GET' })
      const jsonData = await response.json();
      if (jsonData.error) {
        setNoData(true)
      } else {
        setShipmentData(jsonData)
      }
      
    } catch (e) {
      console.log(e)
      setNoData(true)
      setShipmentData(undefined)
    }
    setIsLoading(false)
  }

  // A workaround solution if our shipping partner's DB needs a moment after modifying records
  const fetchShipmentDataWithDelay = async () => {
    setIsLoading(true)
    await new Promise(resolve => setTimeout(resolve, 500))
    await fetchShipmentData()
  }

  const updateShipmentData = (updatedShipmentData, isInsert) => {
    // Instead of reloading the whole data...simply plug in the updated values into our main data object
    if (!!isInsert) {
      // Currently, we translate the ShipBob data to resemble existing shipment data, so we can't update for inserts
      if (false) {
        const clonedShipmentData = { ...shipmentData };
        clonedShipmentData.shipments = [
          ...clonedShipmentData.shipments,
          ...updatedShipmentData
        ]
        setShipmentData(clonedShipmentData)
        setForceTabCountRefresh(!forceTabCountRefresh)
      } else {
        fetchShipmentDataWithDelay()
      }
    }
    if (updatedShipmentData.id) {
      const clonedShipmentData = { ...shipmentData };
      const {
        shipments = []
      } = clonedShipmentData;

      const updateShipmentObj = shipments.find(({ id }) => id === updatedShipmentData.id);
      if (updateShipmentObj) {
        Object.keys(updatedShipmentData).map(key => updateShipmentObj[key] = updatedShipmentData[key])
        setShipmentData(clonedShipmentData)
        setForceTabCountRefresh(!forceTabCountRefresh)
      }
    } 
  }

  const onClickLogo = () => {
    navigate('/shipments')
  }

  useEffect(() => {
    if (accountId) {
      fetchShipmentData()
    } else {
      // Below is used for when url may be hit without an id provided in the url
      setIsLoading(false)
      // setNoData(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  const { accountName, shipments = [] } = shipmentData || {};

  return (
    <Main $isMobileView={isMobileView} 
    $backgroundColor={'#ffffff'}
    >
      <MainContent $isMobileView={isMobileView}>
        <div style={{ padding: '0px' }}>
        { 
          isLoading 
          ?
          <Section style={{ height: '1200px', alignItems: 'center' }}>
            <LoadingIcon />
          </Section>
          :
          noData ?
          <Section style={{ height: '400px' }}>
            Unable to find shipment tracking data
          </Section>
          :
          <FadeInWrapper>
            {
              accountId 
              ?
              <Section style={{ padding: '0px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                  <img onClick={onClickLogo} src={ProsolveLogoSlim} style={{ height: '55px', marginLeft: '-10px', marginRight: '10px', cursor: 'pointer' }} alt="prosolveLogo"/>
                  <div style={{
                    fontSize: '28px',
                    fontWeight: 'bold'
                  }}>
                    {accountName}
                  </div>
                </div>
                <ShipmentList shipments={shipments} updateShipmentData={updateShipmentData} forceTabCountRefresh={forceTabCountRefresh} />
              </Section>
              :
              <Section style={{ padding: '0px', height: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {'Account Id Required'}
              </Section>
            }
          </FadeInWrapper>
        }
        </div>
        <SectionDivider />
        <Footer />
      </MainContent>
    </Main>
  )
}
