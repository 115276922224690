import { FaShare } from "react-icons/fa";
import ActionButton from "../Shared/ActionButton";

export default ({
  wro
}) => {
  const {
    id
  } = wro

  const viewWRO = () => {
    window.open(`https://webstage.shipbob.dev/app/merchant/#/inventory/receiving/${id}/summary/`)
  }

  return (
    <ActionButton onClick={viewWRO}>
      <FaShare style={{ paddingRight: '10px' }} />
      View
    </ActionButton>
  )
}