import styled from "styled-components"
import moment from "moment"
import CancelWROButton from "./CancelWROButton"
import CreateWROButton from "./CreateWROButton"
import ViewWROExternalButton from "./ViewWROExternalButton"

const WROTable = styled.table`
  & {
    width: 100%
  }

  * th {
    text-align: left
  }
`

const statusMap = {
  toDisplay: {
    'Awaiting': 'Awaiting Arrival',
    'PartiallyArrived': 'Hub',
    'Arrived': 'Hub',
    'Processing': 'Hub',
    'PartiallyArrivedAtHub': 'Hub',
    'ArrivedAtHub': 'Hub',
    'ProcessingAtHub': 'Hub',
    'InternalTransfer': 'Internal Transfer',
    //Desination FC has 3 statuses not in API?
    'Completed': 'Complete',
    //'Cancelled': 'Cancelled',
    //'Incomplete': 'Exception',
  },
  display: {
    'Awaiting Arrival': 1,
    'Hub': 1,
    'Internal Transfer': 1,
    'Complete': 1
  }
}

export default ({
  wro,
  updateWROData
}) => {
  const {
    id,
    purchase_order_number,
    insert_date,
    fulfillment_center,
    status
  } = wro

  const is_active = (status !== 'Cancelled')

  const formatDate = () => {
    const displayDate = insert_date
    return displayDate ? `${moment(displayDate).format('MM/DD/YYYY')}` : 'no date found'
  }

  return (
    
    <div style={{
      background: 'white',
      border: '1px solid #dedede',
      borderRadius: '5px',
      marginTop: '20px',
      color: (!!is_active ? '#080808' : '#bfbfbf')
    }}>
      <div style={{
        background: '#f8f9fc',
        borderBottom: '1px solid #dedede',
        padding: '10px 20px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        fontWeight: 'bold'
      }}>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center'
        }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '16px', marginRight: '5px' }}>WRO #{id} - {status}</span>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <ViewWROExternalButton wro={wro} />
            <CreateWROButton wro={wro} />
            {is_active && 
              <CancelWROButton wro={wro} updateWROData={updateWROData} />
            }
          </div>
        </div>
      </div>
      <div>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center'
        }}>
          {Object.entries(statusMap.display).map(([key], i, arr) => 
            <div key={key} style={{ 
              flex: 1, 
              textAlign: 'center',
              background: (statusMap.toDisplay[status] === key && !!is_active ? '#f8f9fc' : 'inherit'), 
              color: (!!is_active ? '#565656' : 'inherit'),
              fontWeight: (statusMap.toDisplay[status] === key ? 'bold' : 'normal'),
              borderRight: (i === arr.length - 1 ? '' : 'solid 1px #dedede'),
              borderBottom: 'solid 1px #dedede',
              padding: '2px 0'
            }}>
              <span>{key}</span>
            </div>
          )}
        </div>
      </div>
      
      <div style={{
        padding: '20px',
        display: 'flex'
      }}>
        <div style={{ flex: 1 }}>
          <WROTable>
            <thead>
              <tr>
                <th>ID</th>
                <th>PO #</th>
                <th>Creation Date</th>
                <th>Fulfillment Center</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{id}</td>
                <td>{purchase_order_number}</td>
                <td>{formatDate()}</td>
                <td>{fulfillment_center.name}</td>
                <td>{status}</td>
              </tr>
            </tbody>
          </WROTable>
        </div>
      </div>
    </div>
  )
}