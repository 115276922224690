import React from 'react';
import ProductDetails from './ProductDetails';

export default ({
  products = []
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px'}}>
    {products.map((product) => 
        <ProductDetails key={product.id} product={product} />
    )}
    </div>
  )
}