import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaArchive, FaClipboard, FaInbox, FaListAlt } from 'react-icons/fa';
import styled from 'styled-components';
import Orders from './Orders/Orders';
import Products from './Products/Products';
import Inventory from './Inventory/Inventory';
import WRO from './WRO/WRO';
import ProsolveLogoSlim from '../images/prosolve-logo-slim.png';

const drawerWidth = 240;

const navItems = [
  { name: 'Orders', url: '', component: <Orders />, icon: <FaClipboard /> },
  { name: 'Products', url: 'products',component: <Products />, icon: <FaListAlt /> },
  { name: 'Inventory', url: 'inventory',component: <Inventory />, icon: <FaArchive /> },
  { name: 'WROs', url: 'wros',component: <WRO />, icon: <FaInbox /> }
]

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  min-height: 100%;
`

/*
 colors:
  button-blue #0280c6
  background-blue: #016092?

  text-blue: #0280c6
  background-powder-blue: #f0faff

  text-gray: #707070
*/
const Aside = styled.div`
  width: calc(${drawerWidth}px - 40px); 
  padding: 20px;
  border-right: solid 1px #C2C9D1;
  background-color: white;
  color: #707070;
`

const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`

const MainTitlebar = styled.div`
  display: flex;
  align-items: center;
  color: #707070;
  height: 50px;
  width: calc(100% - 40px);
  padding: 20px;
  border-bottom: solid 1px #C2C9D1;
`

const MainContent = styled.div`
  flex: 1;
  width: calc(100% - 40px);
  padding: 20px;
  background: #f5f5f5;
`

const Drawer = styled.div`
  width: 100%;
`

const DrawerExpandButton = styled.div`
  display: none;
`

const DrawerPopout = styled.div`
  display: none;
  width: 100%;
`

const DrawerItem = styled(Link)`
  display: flex;
  padding: 20px;
  border-radius: 8px;
  text-decoration: none;
  
  &:link, &:visited, &:active {
    color: #707070;
  }
  &:hover {
    color: #0280c6;
  }

  & .nav-icon {
    padding-right: 10px;
  }
`

export default () => {
  const { tab } = useParams();

  const selectedNav = navItems.find(n => n.url === tab) || navItems[0]

  const [isDrawerPopoutOpen, setIsDrawerPopoutOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerPopoutOpen(!isDrawerPopoutOpen);
  };

  const drawer = (
    <>
      {navItems.map(nav => 
        <DrawerItem 
          key={nav.name}
          to={`/orders/${nav.url}`}
          style={{...(nav.name === selectedNav.name ? { backgroundColor: '#f0faff', color: '#0280c6' } : {})}}
        >
          <span className='nav-icon'>{nav.icon}</span> {nav.name}
        </DrawerItem>
      )}
    </>
  );

  const main = (
    <div>{selectedNav.component}</div>
  )

  return (
    <Layout>
      <MainTitlebar>
        <div>
          <DrawerExpandButton onClick={handleDrawerToggle}>
            ☰
          </DrawerExpandButton>
          <div>
            <img src={ProsolveLogoSlim} style={{ height: '55px' }} alt="prosolveLogo"/>
          </div>
        </div>
      </MainTitlebar>
      
      <MainWrapper>
        <Aside>
          <DrawerPopout>
            {drawer}
          </DrawerPopout>
          <Drawer>
            {drawer}
          </Drawer>
        </Aside>

        <MainContent>
          {main}
        </MainContent>
      </MainWrapper>
    </Layout>
  );
}