import { FaShare } from "react-icons/fa";
import ActionButton from "../Shared/ActionButton";

export default ({
  inventoryItem
}) => {
  const {
    id
  } = inventoryItem

  const viewInventoryItem = () => {
    window.open(`https://webstage.shipbob.dev/app/merchant/#/inventory-status/all?option=Active&pageNumber=1&pageSize=25&filter=Search+eq+${id}`)
  }

  return (
    <ActionButton onClick={viewInventoryItem}>
      <FaShare style={{ paddingRight: '10px' }} />
      View
    </ActionButton>
  )
}