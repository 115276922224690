import InventoryDetails from "./InventoryDetails"

export default ({
  inventory
}) => { 
  return (
    <div>
      {inventory.map(i => {
        return <InventoryDetails key={i.id} inventoryItem={i} />
      })}
    </div>
  )
}