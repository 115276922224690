import styled from "styled-components"
import AddEditProductButton from "./AddEditProductButton"
import ViewProductExternalButton from "./ViewProductExternalButton"

const ProductTable = styled.table`
  & {
    width: 100%
  }

  * th {
    text-align: left
  }
`

export default ({
  product,
  updateProductData
}) => {
  const {
    name,
    reference_id,
    sku,
    channel,
    total_committed_quantity,
    total_fulfillable_quantity,
    total_onhand_quantity
  } = product

  // There is no active property returned. However, all inactive items seem to have a channel id of 0.
  const is_active = (channel.id !== 0)

  return (
    
    <div style={{
      background: 'white',
      border: '1px solid #dedede',
      borderRadius: '5px',
      marginTop: '20px',
      color: (!!is_active ? '#080808' : '#bfbfbf')
    }}>
      <div style={{
        background: '#f8f9fc',
        borderBottom: '1px solid #dedede',
        padding: '10px 20px',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px',
        fontWeight: 'bold'
      }}>
        <div style={{
          display: 'flex',
          flex: 1,
          alignItems: 'center'
        }}>
          <div style={{ flex: 1 }}>
            <span style={{ fontSize: '16px', marginRight: '5px' }}>{product.name}</span>
            {!is_active && <span>(retired)</span>}
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <ViewProductExternalButton product={product} />
            <AddEditProductButton product={product} updateProductData={updateProductData} />
          </div>
        </div>
      </div>
      
      <div style={{
        padding: '20px',
        display: 'flex'
      }}>
        <div style={{ flex: 1 }}>
          <ProductTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Reference Id</th>
                <th>SKU</th>
                <th>On&nbsp;Hand</th>
                <th>Committed</th>
                <th>Fulfillable</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{name}</td>
                <td>{reference_id}</td>
                <td>{sku}</td>
                <td>{total_onhand_quantity}</td>
                <td>{total_committed_quantity}</td>
                <td>{total_fulfillable_quantity}</td>
              </tr>
            </tbody>
          </ProductTable>
        </div>
      </div>
    </div>
  )
}